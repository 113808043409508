import React, {ChangeEvent, Component} from "react";
import {withTranslation} from "react-i18next";
import ApiClientFactory from "../modules/event/models/ApiClientFactory";
import {LocalData} from "../Dto/LocalData";
import LocalDataHandler from "../helper/LocalDataHandler";
import {
    EventRegistratioInformation,
    Group,
    GroupChef,
    GroupWithParticipants, GroupWithParticipantsCount, Participant, Person, RegisterParticipant,
    ShootingProgramDefinition, TmpCapacityInformation, Weapon
} from "../modules/event/models/Models";
import CapacityElement from "./capacityElemnt";

interface RegisterModalState {
    show: boolean;
    localData: LocalData
    groupName: string
    personVisible: boolean
    search: string;
    people: Person[];
    orgGroups: GroupWithParticipants[];
    selectedSpd: number[],
    personsToParticipants: string[],
    selectedWeapons: WeaponPersLicense[],
    eventparticipants: Participant[],
    participantList: number[],
    updateGroupChef : GroupChef | undefined,
    updateGroup : Group | undefined,
    capaDayList : TmpCapacityInformation[] | undefined,
    capacityPerSpd:  GroupWithParticipantsCount[] | undefined,
}

interface WeaponPersLicense {
    selectedWeaponsId: number,
    licenseNr: string
}

interface RegisterModalProps {
    shootingProgrammDefinitionId: number
    shootingProgrammDefinitions: ShootingProgramDefinition[]
    people: Person[];
    orgGroups: GroupWithParticipants[];
    eventRegistrationInfos: EventRegistratioInformation;
    updateObject: number | undefined
}

class NewGroupModal extends Component<RegisterModalProps, RegisterModalState> {
    constructor(props: RegisterModalProps) {
        super(props);
        this.state = {
            show: false,
            localData: LocalDataHandler.loadLocalData(),
            groupName: "",
            personVisible: false,
            search: "",
            people: this.props.people,
            orgGroups: this.props.orgGroups,
            selectedSpd: [],
            personsToParticipants: [],
            selectedWeapons: [],
            eventparticipants: [],
            participantList: [],
            updateGroupChef : undefined,
            updateGroup : undefined,
            capaDayList : [],
            capacityPerSpd: []

        };

        this.handleSave = this.handleSave.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.spdSwithcChange = this.spdSwithcChange.bind(this);
        this.updatePersonToParticipan = this.updatePersonToParticipan.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.weaponChanged = this.weaponChanged.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleChangeTWB = this.handleChangeTWB.bind(this);
    }

    isParticipantAllowed(licenseNr: string) {
        var result = this.state.orgGroups.find(g => g.participants?.find(p => p.person?.licenseNumber === licenseNr))
        if (result && this.state.selectedSpd.find(spd => spd === result?.shootingProgramDefinitionId) ) {
            return false;
        } else {
            return true
        }
    }

    async setFieldsForUpdate() {
        let group : GroupWithParticipants | undefined = this.state.orgGroups?.find(g => g.id === this.props.updateObject);
        let groupChef = await this.apiClient.api.eventsGroupChefDetail(group?.groupChefId as number, this.state.localData.eventId)

        if (group) {
            this.setState({
                updateGroup:group,
                updateGroupChef: groupChef.data,
                groupName:group.groupName as string,
            })
        }
        return group;
    }

    generateParticipantList(licenseNr: string | undefined | null, shootingDefinitionId: number | undefined,) {
        try {
            var checkParticipan
            try {
                checkParticipan = this.state.eventparticipants.find(p => p.person?.licenseNumber === licenseNr);
            } catch (ex) {
                console.log(ex)
            }
            var participant: RegisterParticipant;
            participant = {
                participantNumber: "",
                shootingProgramDefinitionRegistrations: [{
                    shootingProgramDefinitionId: shootingDefinitionId,
                    isOrdered: true,
                    weaponId: this.state.selectedWeapons.find(wl => wl.licenseNr === licenseNr)?.selectedWeaponsId,
                    price: 0,
                    passenCount: 1
                }],
                person: this.state.people.find(p => p.licenseNumber === licenseNr),
                organization: this.state.people.find(p => p.licenseNumber === licenseNr)?.organizations?.find(org => org.number === this.state.localData.orgId),
            };

            if (checkParticipan) {
                return checkParticipan.participantId as number
            } else {
                return this.createParticipan(participant);
            }
        } catch (e) {
            console.log(e)
        }
    }

    async createParticipan(participant: RegisterParticipant) {
        var created = await this.apiClient.api.eventsParticipantsCreate(this.state.localData.eventId, participant);
        if (created.ok) {
            return created.data.participantId as number
        }
        return undefined;
    }


    apiClient = ApiClientFactory.GetApiClient();

    handleShow = (): void => {
        this.setState({show: true});
    };

    handleClose = (): void => {
        this.setState({show: false});
    };

    handleOnChange = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({groupName: event.currentTarget.value});
        const { id, value } = event.target;
        this.setState(prevState => ({
            updateGroup: {
                ...prevState.updateGroup,
                [id]: value
            }
        }));
    }

    handleChangeTWB(event: React.ChangeEvent<HTMLInputElement>) {
        const { id, value } = event.target;
        // Sicherstellen, dass das richtige Feld aktualisiert wird
        this.setState(prevState => ({
            updateGroupChef: {
                ...prevState.updateGroupChef,
                [id]: value
            }
        }));
    }

    handleSave = async (): Promise<void> => {

        var spdIds: number[] = []
        this.props.shootingProgrammDefinitions.map(spdTmp => {
            var tmp = document.getElementById("mod-spd-" + spdTmp.id)
            if (tmp && tmp instanceof HTMLInputElement && tmp.type === "checkbox" && tmp.checked)
                spdIds.push(spdTmp.id as number)
            return ""
        })
        let check = await this.writeAll(spdIds)
        if (check) {
            this.closeModal();
        }
    }

    handleUpdate = async (): Promise<void> => {
        if(this.props.updateObject){
            let groupChef = this.getGroupCchefForm();
            groupChef.id = this.state.updateGroupChef?.id as number;
            let groupTmp = this.state.updateGroup;
            if(groupTmp !== undefined){
                groupTmp.groupName = this.state.groupName as string;
                groupTmp.groupNumber = (document.getElementById("groupNumber") as HTMLInputElement).value !== "" ? parseInt((document.getElementById("groupNumber") as HTMLInputElement).value): 0;
            }
            var resultGroupChef = await this.apiClient.api.eventsGroupChefUpdate(this.state.updateGroupChef?.id as number, this.state.localData.eventId, groupChef);
            if (resultGroupChef) {
                var resultGroup = await this.apiClient.api.eventsGroupsUpdate(groupTmp?.id as number, this.state.localData.eventId, groupTmp as Group);
                if (resultGroup) {
                    this.closeModal()
                }
            }
        }
    }

    componentDidMount() {
        if (!this.state.selectedSpd.find(spd => spd === this.props.shootingProgrammDefinitionId)) {
            this.state.selectedSpd.push(this.props.shootingProgrammDefinitionId)
        }
        this.loadData();
    }


    async loadData() {
        let localData = LocalDataHandler.loadLocalData();
        let result = await this.apiClient.api.eventsParticipantsDetail(localData.eventId)
        if (result.ok) {
            this.setState({eventparticipants: result.data})
        }
        if(this.props.updateObject !== undefined){
            this.setFieldsForUpdate()
        }

        let days = await this.apiClient.api.eventsTmpCapacityInformationDetail(this.state.localData.eventId)
        if(days){
            this.setState({
                capaDayList: days.data
            })
        }

        let capaGroupList = await this.apiClient.api.eventsGroupsShootingProgramGroupsWithParticipantsCountDetail(this.props.shootingProgrammDefinitionId, localData.eventId)
        if(capaGroupList){
            this.setState({
                capacityPerSpd : capaGroupList.data
            })
        }
    }

    checkData(groupChef: GroupChef): boolean {
        var groupNumber = (document.getElementById("groupNumber") as HTMLInputElement).value
        return groupChef.firstName !== ""
            && groupChef.lastName !== ""
            && groupChef.street !== ""
            && groupChef.zipCode !== ""
            && groupChef.city !== ""
            && groupChef.phoneNumber !== ""
            && groupChef.emailAddress !== ""
            && groupChef.streetNumber !== ""
            && groupChef.iban !== ""
            && groupChef.bankName !== ""
            && groupNumber !== ""
    }

    closeModal() {
        alert("Gruppe gespeichert.")
        window.location.reload();
        this.setState({show: false});
    }

    getGroupCchefForm():GroupChef{
        var groupChef: GroupChef = {
            lastName: (document.getElementById("lastName") as HTMLInputElement)?.value || "",
            firstName: (document.getElementById("firstName") as HTMLInputElement)?.value || "",
            street: (document.getElementById("street") as HTMLInputElement)?.value || "",
            zipCode: (document.getElementById("zipCode") as HTMLInputElement)?.value || "",
            city: (document.getElementById("city") as HTMLInputElement)?.value || "",
            phoneNumber: (document.getElementById("phoneNumber") as HTMLInputElement)?.value || "",
            streetNumber: (document.getElementById("streetNumber") as HTMLInputElement)?.value || "",
            emailAddress: (document.getElementById("emailAddress") as HTMLInputElement)?.value || "",
            iban: (document.getElementById("iban") as HTMLInputElement)?.value || "",
            bankName: (document.getElementById("bankName") as HTMLInputElement)?.value || ""
        }
        return groupChef
    }

    async writeAll(spdIds: number[],) {
        let groupChef = this.getGroupCchefForm()
        var groupChefid = await this.apiClient.api.eventsGroupChefCreate(this.state.localData.eventId, groupChef)
        if (groupChefid) {
            if (this.state.groupName.length > 0 && this.checkData(groupChef)) {
                if (!this.props.orgGroups.find(ga => ga.groupName?.toLowerCase() === this.state.groupName.toLowerCase())) {
                    spdIds.map(async (spdIdTmp, index) => {
                        var group: Group = {
                            groupNumber: (document.getElementById("groupNumber") as HTMLInputElement).value !== "" ? parseInt((document.getElementById("groupNumber") as HTMLInputElement).value): 0,
                            groupName: this.state.groupName,
                            organizationNumber: this.state.localData.orgId,
                            /** @format int32 */
                            shootingProgramDefinitionId: spdIdTmp,
                            /** @format int32 */
                            participantsCount: 0,
                            /** @format int32 */
                            maxGroupSize: 99,
                            groupChefId: groupChefid.data as number
                        }
                        await this.saveNewGroup(group).then(() => this.closeModal());
                    })
                } else {
                    alert("Doppelter Gruppenname. Bitte wählen sie ein anderen Namen")
                }

            } else {
                alert("Bitte füllen sie alle Felder aus!")
            }
        }
        return false;
    }

    spdSwithcChange(event: React.ChangeEvent<HTMLInputElement>) {
        var spdId = parseInt(event.target.value)
        this.spdSwitch(spdId)
    }

    spdSwitch(spdId: number) {
        var tmpspdlist;
        if (this.state.selectedSpd.find(spdtmp => spdtmp === spdId)) {
            tmpspdlist = this.state.selectedSpd.filter(p => p !== spdId)
            this.setState({
                selectedSpd: tmpspdlist,
                personVisible: tmpspdlist.length > 1
            })
        } else {
            tmpspdlist = [...this.state.selectedSpd, spdId];
            this.setState({
                selectedSpd: tmpspdlist,
                personVisible: tmpspdlist.length > 1
            })
        }
    }

    getInputSpd(spd: ShootingProgramDefinition) {
        if (spd.id === this.props.shootingProgrammDefinitionId) {
            return <input onChange={this.spdSwithcChange} className="form-check-input" type="checkbox" value={spd.id}
                          role="switch"
                          key={"mod-spd-" + spd.id} id={"mod-spd-" + spd.id} checked={true} disabled={true}/>
        }
        return <input onChange={this.spdSwithcChange} className="form-check-input " type="checkbox" value={spd.id}
                      role="switch"
                      key={"mod-spd-" + spd.id} id={"mod-spd-" + spd.id}/>
    }


    handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({search: event.target.value.toLowerCase()});
    }


    weaponChanged(event: React.ChangeEvent<HTMLInputElement>) {
        var _weaponId: number = parseInt(event.target.value);
        var _licenseNr = event.target.id.replace("mod-spd-weapon-", "");
        let updateList = this.state.selectedWeapons.filter(wl => wl.licenseNr !== _licenseNr);
        var entry: WeaponPersLicense = {
            selectedWeaponsId: _weaponId,
            licenseNr: _licenseNr
        }
        updateList.push(entry)
        this.setState({selectedWeapons: updateList});
    }

    weaponSelect(spdId: number | undefined, person: Person) {
        // @ts-ignore
        let selectElement = [];
        let weapons: Weapon[] = new Array<Weapon>()
        selectElement.push(<option key={"mod-pl-" + person.licenseNumber + "-empty"} value={"empty"}>Waffe
            wählen</option>)
        // @ts-ignore
        this.props.eventRegistrationInfos.disciplines?.find(d => d.shootingProgramDefinitions?.find(spd => spd.id === spdId)).fields.map(f => {
            f?.weapons?.sort((a: Weapon, b: Weapon) => {
                const shortNameA = a.shortName ?? '';
                const shortNameB = b.shortName ?? '';
                return shortNameA.localeCompare(shortNameB);
            }).map((w: Weapon) => {
                if (!weapons?.find(test => test.id === w.id)) {
                    weapons?.push(w)
                    selectElement.push(<option
                        key={"f-" + f.id + "w-" + w.id + "-pl-" + person.licenseNumber}
                        value={w.id}>{w.shortName}</option>)
                }
                return ""
            })
            return ""
        })

        // @ts-ignore
        if (this.state.personsToParticipants.find(ln => ln === person.licenseNumber)?.length > 0) {
            return <div></div>
        }
        // @ts-ignore
        return <select onChange={this.weaponChanged} id={"mod-spd-weapon-" + person.licenseNumber}
                       className="">{selectElement}</select>;
    }

    showPersonByMultiSpd(spd: ShootingProgramDefinition) {
        if (!this.state.personVisible) {
            return <div></div>
        }
        return <div>
            <div className="border-top my-3"></div>
            <h4>Teilnehmerauswahl</h4>

            <div key={"mod-" + spd.name} className={"row "}>
                <div className="col-12 py-3">

                    <table className={"table table-sm table-hover table-striped"}>
                        <thead>
                        <tr>
                            <th className={"text-start col-2"}><i
                                className="bi bi-file-person"/> Schütze <input
                                placeholder={"suchen"} onChange={this.handleChange}/></th>
                            <th className={"text-center col-1"}>
                                Jahrgang {/*<!-- <i className="bi bi-crosshair"/> Waffen -->*/}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.people
                            .filter(p => p.lastName?.toLowerCase().includes(this.state.search) || p.firstName?.toLowerCase().includes(this.state.search))
                            .sort((a, b) => {
                                if (a.lastName == null || b.lastName == null) {
                                    return 0;
                                }
                                return a.lastName.localeCompare(b.lastName);
                            }).map(person => {
                                return <tr key={"mod-pers" + person.id}>
                                    <td className={"text-start "}>{person.lastName + " " + person.firstName}</td>
                                    <td className={"text-center "}>{person.birthday?.split("-")[0]}</td>
                                    <td className={"text-start "}>{this.weaponSelect(spd.id, person)}</td>
                                    <td>{this.getPersonCheckBox(spd, person)}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    }

    updatePersonToParticipan(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.value) {
            var licenseNumber: string = event.target.value as string;
            if (!this.state.personsToParticipants.find(ln => ln === licenseNumber)) {
                if ((document.getElementById("mod-spd-weapon-" + licenseNumber) as HTMLInputElement).value === "empty") {
                    alert("Bitte Waffe auswählen")
                } else {
                    this.setState({
                        personsToParticipants: [...this.state.personsToParticipants, licenseNumber]
                    })
                    this.getSelectedParticipants(licenseNumber, false)
                }
            } else {
                this.setState({
                    personsToParticipants:  this.state.personsToParticipants.filter(ln => ln !== licenseNumber)
                })
                this.getSelectedParticipants(licenseNumber, true)
            }
        }

    }


    getPersonCheckBox(spd: ShootingProgramDefinition, person: Person) {
        if ((spd.groupSize !== undefined
                && spd.groupSize > this.state.personsToParticipants.length
            && this.isParticipantAllowed(person.licenseNumber as string)) // kollisionscheck
            || this.state.personsToParticipants.find(ln => ln === person.licenseNumber)
        ) {
            // @ts-ignore
            var check = this.state.personsToParticipants.find(ln => ln === person.licenseNumber)?.length > 0
            return <td className={"text-center"}
                key={"mod-g-" + person.licenseNumber}>
                <input
                    value={person.licenseNumber as string}
                    type={"checkbox"}
                    onChange={this.updatePersonToParticipan}
                    checked={check}
                /></td>
        } else {
            return <td className={"text-center"}
                key={"mod-g-" + person.licenseNumber}>
                {(spd.groupSize !== undefined && spd.groupSize <= this.state.personsToParticipants.length) ? <input

                    type={"checkbox"}
                    checked={false}
                    disabled={true}
                /> : "bereits in anderer Gruppe"}

            </td>
        }
    }


    getSpd() {
        return <div>
            <div className="border-top my-3"></div>
            <div><h4>Gruppeninformation auf Stich anwenden</h4></div>
            <div className={"container"}>
                {this.props.shootingProgrammDefinitions.map((spd: ShootingProgramDefinition) => {
                    return <div className={"row"} key={"getspd-" + spd.id}>
                        <div className={"col-8"}></div>
                        <div className="form-check form-switch col-4" key={"mod-" + spd.id + "keyspd"}>
                            <label className="form-check-label"
                                   htmlFor="flexSwitchCheckCheckedDisabled">{spd.name}</label>
                            {this.getInputSpd(spd)}

                        </div>
                    </div>
                })}
            </div>
        </div>

    }


    daysPersSpd() {
        var daylist: TmpCapacityInformation[] = []
        this.state.capaDayList?.map(days => {
            if(days.shootingProgrammDefinitionId === this.props.shootingProgrammDefinitionId){
                daylist.push(days)
            }
        })
        return daylist.sort()
    }

    getParticipantCount(day:number):number{
        if(this.state.capacityPerSpd?.find(g => g.groupNumber === day) !== undefined){
            return this.state.capacityPerSpd?.find(g => g.groupNumber === day)?.participantCount as number
        }
        return 0
    }

    getTimeSlots() {

        return <div>
            <div className="border-top my-3"></div>
            <h4>Zeitfenster</h4>
            <div className="border-top my-3"></div>
            <select id={"groupNumber"} className={"form-select"}>
                <option key={"mpd-time-select-0"} value={""} selected={(this.state.updateGroup?.groupNumber === undefined)? true : false}>Bitte Zeitfenster auswählen</option>
                <option key={"mpd-time-select-1"} value={1} selected={(this.state.updateGroup?.groupNumber!==undefined && this.state.updateGroup?.groupNumber === 1)? true : false}>Samstag 12. Oktober 2024 08:00 - 12:00</option>
                <option key={"mpd-time-select-2"} value={2} selected={(this.state.updateGroup?.groupNumber!==undefined && this.state.updateGroup?.groupNumber === 2)? true : false}>Samstag 12. Oktober 2024 13:00 - 17:00</option>
                <option key={"mpd-time-select-3"} value={3} selected={(this.state.updateGroup?.groupNumber!==undefined && this.state.updateGroup?.groupNumber === 3)? true : false}>Freitag 18. Oktober 2024 13:00 - 18:00</option>
                <option key={"mpd-time-select-4"} value={4} selected={(this.state.updateGroup?.groupNumber!==undefined && this.state.updateGroup?.groupNumber === 4)? true : false}>Samstag 19. Oktober 2024 08:00 - 12:00</option>
                <option key={"mpd-time-select-5"} value={5} selected={(this.state.updateGroup?.groupNumber!==undefined && this.state.updateGroup?.groupNumber === 5)? true : false}>Samstag 19. Oktober 2024 13:00 - 17:00</option>
            </select>
            <div className="border-top my-3"></div>
            <h5>Auslastung</h5>
            <div>Samstag 12. Oktober 2024 08:00 - 12:00 </div>
            <CapacityElement timaCapacity={this.daysPersSpd()[0].timeContigent as number} timeSlot={this.daysPersSpd()[0].timeSlot as number} targetCount={this.daysPersSpd()[0].targetCount as number} participantsCount={this.getParticipantCount(1)}/>
            <div>Samstag 12. Oktober 2024 13:00 - 17:00</div>
            <CapacityElement timaCapacity={this.daysPersSpd()[1].timeContigent as number} timeSlot={this.daysPersSpd()[1].timeSlot as number} targetCount={this.daysPersSpd()[1].targetCount as number} participantsCount={this.getParticipantCount(2)}/>
            <div>Freitag 18. Oktober 2024 13:00 - 18:00</div>
            <CapacityElement timaCapacity={this.daysPersSpd()[2].timeContigent as number} timeSlot={this.daysPersSpd()[2].timeSlot as number} targetCount={this.daysPersSpd()[2].targetCount as number} participantsCount={this.getParticipantCount(3)}/>
            <div>Samstag 19. Oktober 2024 08:00 - 12:00</div>
            <CapacityElement timaCapacity={this.daysPersSpd()[3].timeContigent as number} timeSlot={this.daysPersSpd()[3].timeSlot as number} targetCount={this.daysPersSpd()[3].targetCount as number} participantsCount={this.getParticipantCount(4)}/>
            <div>Samstag 19. Oktober 2024 13:00 - 17:00</div>
            <CapacityElement timaCapacity={this.daysPersSpd()[4].timeContigent as number} timeSlot={this.daysPersSpd()[4].timeSlot as number} targetCount={this.daysPersSpd()[4].targetCount as number} participantsCount={this.getParticipantCount(5)}/>
        </div>
    }

    getBankData() {
        return <div>
            <div className="border-top my-3"></div>
            <h4>Bankdaten</h4>
            <div className="row py-2">
                <div className="col-6">
                    <input type="text" className="form-control" id="iban"
                           placeholder="Bankverbindung IBAN" required={true} onChange={this.handleChangeTWB}
                           value={this.state.updateGroupChef?.iban as string}/>
                </div>
                <div className="col-6">
                    <input type="text" className="form-control" id="bankName"
                           placeholder="Banknamen oder Post" required={true} onChange={this.handleChangeTWB} value={this.state.updateGroupChef?.bankName as string}/>
                </div>
            </div>
        </div>
    }



    getGroupChef() {
        return <div>
            <div className="border-top my-3"></div>
            <h4>Gruppenchef</h4>
            <div className="row py-2">
                <div className="col-6">
                    <input type="text" className="form-control" id="firstName"
                           placeholder="Vorname" required={true} onChange={this.handleChangeTWB} value={this.state.updateGroupChef?.firstName as string} />
                </div>
                <div className="col-6">
                    <input type="text" className="form-control" id="lastName"
                           placeholder="Name " required={true}  onChange={this.handleChangeTWB} value={this.state.updateGroupChef?.lastName as string} />
                </div>
            </div>
            <div className="row py-2">
                <div className="col-8">
                    <input type="text" className="form-control" id="street"
                           placeholder="Strasse" required={true} onChange={this.handleChangeTWB} value={this.state.updateGroupChef?.street as string} />
                </div>
                <div className="col-4">
                    <input type="text" className="form-control" id="streetNumber"
                           placeholder="Nr " required={true} onChange={this.handleChangeTWB} value={this.state.updateGroupChef?.streetNumber as string} />
                </div>

            </div>
            <div className="row py-2">
                <div className="col-4">
                    <input type="text" className="form-control" id="zipCode"
                           placeholder="PLZ " required={true} onChange={this.handleChangeTWB} value={this.state.updateGroupChef?.zipCode as string} />
                </div>
                <div className="col-8">
                    <input type="text" className="form-control" id="city"
                           placeholder="Ort " required={true}  onChange={this.handleChangeTWB} value={this.state.updateGroupChef?.city as string} />
                </div>
            </div>

            <div className="row py-2">
                <div className="col-6">
                    <input type="text" className="form-control" id="phoneNumber"
                           placeholder="Telefonnummer "  required={true} onChange={this.handleChangeTWB} value={this.state.updateGroupChef?.phoneNumber as string} />
                </div>
                <div className="col-6">
                    <input type="email" className="form-control" id="emailAddress"
                           placeholder="E-Mail Adresse " required={true} onChange={this.handleChangeTWB} value={this.state.updateGroupChef?.emailAddress as string}/>
                </div>
            </div>
        </div>
    }

    async getSelectedParticipants(licenseNr:string, isDelete: boolean) {
            let partId: number | undefined = await this.generateParticipantList(licenseNr, this.props.shootingProgrammDefinitionId)
            if (partId !== undefined && !isDelete) {
                let tmpList: number[] = this.state.participantList.filter(p => p !== partId)
                tmpList.push(partId)
                this.setState({
                    participantList: tmpList
                })
            }
            if(partId !== undefined && isDelete){
                this.setState({
                    participantList: this.state.participantList.filter(p => p !== partId)
                })
            }
    }

    async saveNewGroup(group: Group) {
        try {
            let result = await this.apiClient.api.eventsGroupsCreate(this.state.localData.eventId, group)
            if (result) {
                if (this.state.personVisible) {
                    var result2 = await this.apiClient.api.eventsGroupsParticipantsUpdate(result.data as number, this.state.localData.eventId, this.state.participantList)
                    if (result2.ok) {
                        var data = result2.data
                    }
                }
                return true
            } else {
                alert("Es gab ein Problem beim speichern der Daten")
                return false
            }
        } catch (err) {
            console.error(err);
        }
        return false;
    }

    getButton(){
        if(this.props.updateObject === undefined) {
            return <button type="button" className="btn btn-primary" onClick={this.handleShow}>
                Neu Gruppe
            </button>
        }else {
            return <button type="button" className="btn btn-primary" onClick={this.handleShow}>
                <i className="bi bi-pen-fill"/>
            </button>
        }
    }



    getBottomButtonGroup() {
        if(this.props.updateObject === undefined) {
            return <>
                <button type="button" className="btn btn-secondary" onClick={this.handleClose}>
                    Schliessen
                </button>
                <button type="button" className="btn btn-primary" onClick={this.handleSave}>
                    Speichern
                </button>
            </>
        }else{
            return <>
                <button type="button" className="btn btn-secondary" onClick={this.handleClose}>
                    Schliessen
                </button>
                <button type="button" className="btn btn-primary" onClick={this.handleUpdate}>
                    Update
                </button>
            </>
        }
    }

    render() {
        const {show} = this.state;

        return (
            <>
                {this.getButton()}
                {show && (
                    <div className="modal modal-lg show d-block" tabIndex={-1} role="dialog"
                         aria-labelledby="exampleModalLabel"
                         aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{this.props.updateObject === undefined ? "Gruppe Erstellen" : "Gruppe aktualisieren"}</h5>
                                    <button type="button" className="btn-close" aria-label="Close"
                                            onClick={this.handleClose}></button>
                                </div>
                                <form>
                                    <div className="modal-body">
                                        <div className={"container"}>
                                            <h4>Gruppenname</h4>
                                            <div className="row py-2">
                                                <div className="col-12">
                                                    <input type="text" className="col-9 form-control" id="groupName"
                                                           name="groupName"
                                                           placeholder="Gruppenname " onChange={this.handleOnChange} value={this.state.updateGroup?.groupName as string} />
                                                </div>

                                            </div>

                                            {this.getGroupChef()}

                                            {this.getBankData()}

                                            { this.getTimeSlots()}
                                            { this.props.updateObject === undefined ? this.getSpd(): ""}
                                            { this.props.updateObject === undefined ? this.showPersonByMultiSpd(this.props.shootingProgrammDefinitions[0]):""}
                                        </div>
                                    </div>

                                    <div className="modal-footer">
                                        {this.getBottomButtonGroup() }
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
                {show && <div className="modal-backdrop fade show"></div>}
            </>
        );
    }
};

export default withTranslation()(NewGroupModal);