import {Component} from "react";
import {UserData} from "../core/models/Models";

export default  class Logout extends Component{

    private static userData: UserData | undefined = undefined;

    componentDidMount() {
    }

    clean() {

    }



    render(){
        return <div>
         <a className={"btn btn-primary"} onClick={this.clean} href={`${process.env.REACT_APP_AUTH_LOCATION}/realms/${process.env.REACT_APP_REALM}/protocol/openid-connect/logout/`}>Logout</a> </div>
    }
}