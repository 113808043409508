import * as React from "react";
import {useEffect, useState} from "react";
import ApiClientFactory from "../core/models/ApiClientFactory";

function Login() {
    let [theState, setTheState] = useState({name: "", isLoggedIn: false});

    useEffect(() => {
        return () => {
            window.clearTimeout(timer);
        }
    })


    let timer = 0;
    async function checkUser() {
        let apiClient = ApiClientFactory.GetApiClient();

        try {
            let userCheckResult = await apiClient.api.coreUserCheckList();
            if (userCheckResult.ok) {
                if (theState.isLoggedIn !== userCheckResult.data.isAuthenticated) {
                    setTheState({name: userCheckResult.data.name!, isLoggedIn: true});
                }
            } else if (userCheckResult.status === 401) {
                window.location.href = `${process.env.REACT_APP_API_LOCATION}/api/core/user/login?returnUrl=${getCurrentLocation(true)}`;
            }
        } catch (e) {
            window.location.href = `${process.env.REACT_APP_API_LOCATION}/api/core/user/login?returnUrl=${getCurrentLocation(true)}`;
        }

        timer = window.setTimeout(() => {checkUser();}, 90000);
    }

    function getCurrentLocation(includePath: boolean): string {
        if (includePath) {
            return encodeURIComponent(window.location.toString());
        }
        return encodeURIComponent(window.location.origin);
    }

    checkUser();

    return <div className="container-fluid text-end">
        {theState.name}
    </div>
}

export default Login;