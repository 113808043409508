import {Component} from "react";
import {withTranslation} from "react-i18next";
import {Event, GroupWithParticipants} from "../modules/event/models/Models";
import ApiClientFactory from "../modules/event/models/ApiClientFactory";
import {NavLink} from "react-router-dom";
import {LocalData} from "../Dto/LocalData";
import LocalDataHandler from "../helper/LocalDataHandler";

class Overview extends Component<OverviewProps, OverviewState> {

    apiClient = ApiClientFactory.GetApiClient();

    constructor(props: OverviewProps) {
        super(props);
        this.state = {
            events: [],
            localData: {
                orgId: "",
                eventId: ""
            },
            grouEvent: []
        }
        this.selectHandler = this.selectHandler.bind(this);
    }

    componentDidMount() {
        var localData: LocalData = LocalDataHandler.loadLocalData();
        this.setState({
            localData: localData
        })
        this.loadData();
    }

    async loadGroupsByOrganisation(event: Event){
        const registeredGroup = await this.apiClient.api.eventsGroupsOrganisationDetail(this.state.localData.orgId, event.id+"");
        if(registeredGroup.ok){
            var eg = this.state.grouEvent;
            registeredGroup.data.map(group => {
                if(!eg.find(grouptest => grouptest.groupId === group.id)) {
                    eg.push({eventId: event.id as number, event: event, group: group, groupId: group.id as number});
                }

            })
            this.setState({grouEvent : eg})
        }
    }

    async loadData(){
        try{
            var eventsResponse = await this.apiClient.api.eventsEventsRegistrationEventsList();
            var events: Event[] = []
            if(eventsResponse.ok){
                this.setState({events : eventsResponse.data})
                events = eventsResponse.data;
            }
           events.map(e => {
               this.loadGroupsByOrganisation(e)
           })

        }catch(e){
            console.error(e);
        }
    }

    saveInfos(eventId:string){
        var data: LocalData = {
            orgId : this.state.localData !== undefined ? this.state.localData.orgId : "",
            eventId : eventId
        }
        this.setState({
            localData: data
        });
        LocalDataHandler.writeLocalData(data);
    }

    selectHandler(event: React.ChangeEvent<HTMLSelectElement>) {
       this.saveInfos(event.target.value)
    }

   showButton(){
        if(this.state.localData !== undefined && this.state.localData?.eventId !== "" ){
            return <NavLink className={"btn btn-primary"} to={"/registration/"}>Gruppe erstellen</NavLink>
        }
   }

    async deleteGroup(groupeId: number, groupName: string) {
        try {
            var result = await this.apiClient.api.eventsGroupsDelete(groupeId, this.state.localData.eventId)
            if (result.ok) {
                alert("Gruppe : " + groupName + " gelöscht")
                window.location.reload();
            }
        } catch (e) {

        }
    }


    render() {
        return (
                <div className="container-md px-5 gx-5 shadow-lg mt-lg-5">
                    <div className="row py-3">
                        <div className={"row "}>
                            <div className={"col-12 text-start py-2 "}><h2>Übersicht</h2></div>
                        </div>
                        <div className={"row shadow-lg"}>
                            <div className={"col-12 text-start py-3" }>
                                <h3>Anmeldung für weitere Veranstalltungen</h3>
                                <form>

                                    <div className="mb-3">
                                        <label htmlFor="veranstaltung"
                                               className="form-label">Schießveranstaltung</label>
                                        <select onChange={this.selectHandler} className="form-select" id="veranstaltung"
                                                name="veranstaltung">
                                            <option key={`emptyEevent`} value={""}>Bitte Veranstalltung auswählen</option>
                                            {
                                                this.state.events.map((event) => {
                                                        return <option key={`event-${event.id}`}
                                                                       value={event.id}>{event.name}</option>
                                                    }
                                                )
                                            }
                                        </select>
                                    </div>
                                    {
                                        this.showButton()
                                    }

                                </form>
                            </div>

                        </div>
                        <div className={"row shadow-lg mt-lg-5"}>
                            <div className="col-12 py-3">
                                <h3 className={"text-start py-3"}>Angemeldete Gruppen</h3>
                                <table className={"table table-sm table-hover table-striped"}>
                                    <thead>
                                    <tr>
                                        <th className={"text-start"}><i className="bi bi-cursor-fill"/> Event</th>
                                        <th className={"text-start"}><i className="bi bi-bullseye"/> Gruppenname</th>
                                        <th><i className="bi bi-pencil-square"/> # Teilnehmer</th>

                                        <th><i className="bi bi-pencil-square"/> max. # Teilnehmer</th>
                                    </tr>

                                    </thead>
                                    <tbody>
                                    {this.state.grouEvent.map((group) => {  //TODO
                                       return <tr key={group.groupId}>
                                            <td className={"text-start"}>{group.event.name}</td>
                                           <td className={"text-start"}>{group.group.groupName}</td>
                                            <td>{group.group.participants?.length}</td>
                                            <td>{group.group.maxGroupSize}</td>

                                           <td>
                                               <button type="button" className="btn btn-primary"
                                                       onClick={() =>  {
                                                           this.saveInfos(group.eventId.toString())
                                                           window.location.href = "/registration/"}
                                                       }>
                                                   <span className={"bi-pencil-square"}/>
                                               </button>
                                               &nbsp;
                                               <button
                                                   onClick={() => this.deleteGroup(group.group.id as number, group.group.groupName as string)}
                                                   className={'btn btn-danger'}><span
                                                   className={"bi-trash3-fill"}> </span></button>
                                           </td>
                                       </tr>
                                    })}
                                    </tbody>
                                </table>

                            </div>
                        </div>


                    </div>
                    {/* JSX code goes here */}
                </div>
        );
    }
}

interface OverviewState {
    events: Event[]
    localData: LocalData
    grouEvent : EventGroup[]
}

interface OverviewProps {

}

interface EventGroup {
    eventId: number;
    event: Event;
    group:GroupWithParticipants;
    groupId: number;
}

export default withTranslation()(Overview);