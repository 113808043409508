import {Component} from "react";
import Login from "../modules/common/Login";
import Logout from "../modules/common/Logout";


export class TopRegistration extends Component<TopRegistrationProperties,TopRegistrationState> {


    render() {
        return <nav className="navbar" style={{backgroundColor: "#ffeb00"}}>
            <div className="container-fluid">

                <div className={"row col-12"}>
                    <div className={"col text-start"}><h1
                        className={"flextext"}>{process.env.REACT_APP_PRODUCT_NAME}</h1></div>
                    <div className={"col"}><h1 className={"flextext"}>{this.props.titel}</h1></div>
                    <div className={"col text-end flextext"}>by {process.env.REACT_APP_COMPANY_NAME}</div>
                    <div className={"row"}>
                        <div className={"col-11 text-end align-middle"}><Login/></div>
                        <div className={"col-1 text-end align-middle"}><Logout/> </div>
                    </div>
                </div>
            </div>
        </nav>
    }
}

interface TopRegistrationProperties {
    titel: string;
}

interface TopRegistrationState {

}