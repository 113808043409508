import {Component} from "react";
import {withTranslation} from "react-i18next";

class CapacityElement extends Component<CapacityElemntProps, {}>{

    constructor(props: CapacityElemntProps) {
        super(props);
    }

    render() {
        var capacity = this.props.timaCapacity*this.props.targetCount
        var usedCapacity = this.props.timeSlot*this.props.participantsCount
        return <div className="progress progress-bar-animated" role="progressbar" aria-label="Warning with label" aria-valuenow={usedCapacity/capacity*100}
                    aria-valuemin={0} aria-valuemax={100}>
            <div className="progress-bar bg-warning text-dark" style={{"width": usedCapacity/capacity*100+"%"}} >{(usedCapacity/capacity*100).toFixed(2)}%</div>
        </div>
    }
}

interface CapacityElemntProps {
    targetCount : number
    timaCapacity : number
    timeSlot : number
    participantsCount : number
}

export default withTranslation()(CapacityElement);