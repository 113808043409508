import React from 'react';
import './App.css';
import {TopRegistration} from "./component/topRegistration";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Notmapped from "./sites/notemapped";
import Overview from "./sites/overview";
import "bootstrap-icons/font/bootstrap-icons.css";
import Registration from "./sites/registration";

function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <TopRegistration titel={""}/>
                <Routes>
                    <Route path="" element={<Notmapped/>}/>
                    <Route path="overview" element={<Overview/>}/>
                    <Route path="registration" element={<Registration/>}/>
                </Routes>

            </div>
        </BrowserRouter>
    );
}

export default App;
