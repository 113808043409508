import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import ApiClientFactory from "../modules/event/models/ApiClientFactory";
import {Event, PersonMatchInOrganization} from "../modules/event/models/Models";
import {LocalData} from "../Dto/LocalData";
import LocalDataHandler from "../helper/LocalDataHandler";

class Notmapped extends Component<NotmappedProps, NotmappedState> {

    constructor(props: any) {
        super(props);
        this.formHandler = this.formHandler.bind(this);
        this.state = {
            events: [],
            localData: {
                orgId: "",
                eventId: ""
            }
        };
        this.selectHandler = this.selectHandler.bind(this);
    }

    async loadData(){
        var events = await this.apiClient.api.eventsEventsRegistrationEventsList();
        if(events.ok){
            this.setState({events : events.data});
        }
    }

    componentDidMount() {
        this.loadData()
    }


    apiClient = ApiClientFactory.GetApiClient();

    async formHandler(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const form = event.target as HTMLFormElement;
        const organizationNumber = (form.elements.namedItem('organizationNumber') as HTMLInputElement).value;
        const licenseNumber = (form.elements.namedItem('licenseNumber') as HTMLInputElement).value;

        var pers: PersonMatchInOrganization = {
            licenseNumber : licenseNumber,
            organizationNumber : organizationNumber
        }

        if(this.state.localData.eventId !==""){
            try{
                var person = await this.apiClient.api.eventsPeopleMatchCreate(this.state.localData.eventId, pers)
                if(person.ok){

                    var data : LocalData = {
                        orgId : organizationNumber,
                        eventId : this.state.localData.eventId
                    }
                    // @ts-ignore
                    localStorage.setItem(process.env.REACT_APP_BROWSER_LOCALSTORE_NAME, JSON.stringify(data))
                    var result = await this.apiClient.api.eventsPersonsRoleeditorCreate({
                        eventId : parseInt(this
                        .state.localData.eventId)
                })
                    if(result){
                        window.location.href = "/registration/";
                    }

                }else{
                    alert("Es gibt keinen Match für diese Kombination")
                }
            }catch(e){
                alert("Es gibt keinen Match für diese Kombination")
            }

        }
    }

    saveInfos(eventId:string){
        var data: LocalData = {
            orgId : this.state.localData !== undefined ? this.state.localData.orgId : "",
            eventId : eventId
        }
        this.setState({
            localData: data
        });
        LocalDataHandler.writeLocalData(data)
    }

    selectHandler(event: React.ChangeEvent<HTMLSelectElement>) {
        this.saveInfos(event.target.value)
    }


    render() {
        return (

                <div className="container shadow-lg mt-md-5">
                    <div className="row py-5">
                        <div className="col-12">
                            <h2>Herzlich Willkommen auf dem WinFire 5 Anmeldungsportal.</h2>
                            <p>Damit wir sie mit ihrem Verein verbinden können, geben sie bitte die Daten ein zur Verifikation</p>
                            <div className={"container-fluid"}>
                                <div className="row">
                                    <div className="col-12">
                                        <form onSubmit={this.formHandler}>
                                            <div className="mb-3">
                                                <label htmlFor="veranstaltung"
                                                       className="form-label">Schiessveranstaltung</label>
                                                <select onChange={this.selectHandler} className="form-select"
                                                        id="veranstaltung"
                                                        name="veranstaltung">
                                                    <option key={`emptyEevent`} value={""}>Bitte Veranstalltung
                                                        auswählen
                                                    </option>
                                                    {
                                                        this.state.events.map((event) => {
                                                                return <option key={`event-${event.id}`}
                                                                               value={event.id}>{event.name}</option>
                                                            }
                                                        )
                                                    }
                                                </select>
                                            </div>
                                            <div className="input-group mb-3" >
                                                <span className="input-group-text">Vereins-Nr. SSV</span>
                                                <input disabled={this.state.localData.eventId === ""} id={"organizationNumber"} type="text" className="form-control"
                                                       placeholder= {this.state.localData.eventId === "" ? "Event auswählen" : ""}
                                                       aria-label="Vereins-Nr. SSV"/>
                                            </div>
                                            <div className="input-group mb-3">
                                                <span className="input-group-text">Lizenz-Nr. des Anmelders</span>
                                                <input disabled={this.state.localData.eventId === ""} id={"licenseNumber"} type="text" className="form-control"
                                                       placeholder= {this.state.localData.eventId === "" ? "Event auswählen" : ""}
                                                       aria-label="Lizenz-Nr. des Anmelders"/>
                                            </div>
                                            <button type="submit" className="btn btn-primary">Verbinden</button>
                                            <br/>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    }
}

interface NotmappedState {
    events: Event[]
    localData: LocalData
}

interface NotmappedProps {

}

export default withTranslation()(Notmapped);